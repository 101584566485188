<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <MyRight />
    <div>
      <div class="img-box">
        <img src="./../static/img-box.png" alt="">
        <div class="img-text">
          <div class="img-text-title1">保障薪权益</div>
          <div class="img-text-title2">为农民工工资保驾护航！</div>
        </div>
      </div>
      <div style="display: flex;justify-content: center;">
        <div class="content">
          <div v-for="(item,index) in NewsList" :key="item.id">
            <div style="display: flex;justify-content: space-between;margin-bottom:20px;">
              <div style="width: 250px;height:150px;"><img :src="item.annex" alt=""></div>
              <div style="width:77%;display: flex;flex-direction: column;justify-content: space-between;">
                <div style="display: flex;justify-content: space-between;">
                  <div style="font-size: 18px;color: #000;">{{item.title}}</div>
                  <div style="font-size: 14px;color: rgb(102, 102, 102);">{{item.updateTime}}</div>
                </div>
                <div class="content-text">{{item.articleContent}}</div>
                <div class="more" @click="toNewsDetail(item.id)">查看更多</div>
              </div>
            </div>
            <div v-if="index !== NewsList.length-1" style="width: 100%;height:21px;border-top:1px solid rgba(233, 237, 242, 1);"></div>
          </div>
          <div class="demo-pagination-block">
            <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize" :page-sizes="[10, 20, 40]" :size="size" :disabled="disabled" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" background />
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import MyRight from "@/components/MyRight.vue";
import { list } from "@/api/index.js"
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
let router = useRouter()
let NewsList = ref()
let currentPage = ref(1)
let pageSize = ref(10)
let size = ref('default')
let disabled = ref(false)
let total = ref()
let NewsArr = async () => {
  await list({ current: currentPage.value, size: pageSize.value, type: 1 }).then(res => {
    res.data.records.forEach((item) => {
      item.annex = JSON.parse(item.annex)
    })
    NewsList.value = res.data.records
    total.value = res.data.total
  })
}
let handleSizeChange = (val) => {
  pageSize.value = val
  NewsArr()
}
let handleCurrentChange = (val) => {
  currentPage.value = val
  NewsArr()
}
let toNewsDetail = (id) => {
  router.push({ path: '/newsListDetail', query: { id: id } })
}
onMounted(async () => {
  await NewsArr()
})
</script>
<style scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.img-box {
  width: 100%;
  height: 380px;
  position: relative;
}
.img-text {
  width: 1300px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.img-text-title1 {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 35.16px;
  color: rgba(51, 51, 51, 1);
  margin: 130px 0 0 100px;
}
.img-text-title2 {
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 49.22px;
  color: rgba(0, 82, 217, 1);
  margin: 10px 0 0 100px;
}
.demo-pagination-block {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
}
.header-img {
  width: 100%;
  height: 340px;
  background: url("./../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
.title-img {
  width: 360px;
  height: 119px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content {
  min-height: 100px;
  width: 1300px;
  margin-top: 30px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 30px 52px;
  box-sizing: border-box;
}
.content-text {
  font-size: 14px;
  color: rgb(102, 102, 102);
  text-align: justify;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.more {
  width: 150px;
  height: 40.41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(
      135deg,
      rgba(31, 112, 247, 1) 0%,
      rgba(85, 155, 254, 1) 100%
    ),
    rgba(0, 82, 217, 1);
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
</style>
